.form-control-file {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 80px;
    outline: none;
    visibility: hidden;
    cursor: pointer;
    background-color:none;
    box-shadow: 0 0 5px solid currentColor;
  }
  .form-control-file:before {
    content: attr(data-title);
    position: absolute;
    top: 0.5em;
    left: 0;
    width: 100%;
    line-height: 2em;
    padding: 20px 0;
    opacity: 1;
    visibility: visible;
    text-align: center;
    border: 0.25em dashed #140a9a;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    overflow: hidden;
    box-sizing: border-box;
  }
  .inputfile{
      margin-bottom: 8px;
  }
  .uploadprogress {
    display: inline-block;
    width: 95%;
    margin-right: 10px;
}